.features-container__feature {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: row;
    background-color: rgba(111, 36, 203, 0.095);
    border-radius: 25px;
    margin: 1rem;
    padding: 1.5rem;
    cursor: none;
}

.features-container__feature-title {
    flex: 1;
    max-width: 250px;
    margin-right: 2rem;
}

.features-container__feature-title h1 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 30px;
    line-height: 35px;
    letter-spacing: -0.04em;
    color: #000000;
}

.features-container__feature-title div {
    width: 38px;
    height: 3px;
    background: rgb(190,114,35);
    background: linear-gradient(90deg, rgba(190,114,35,1) 0%, rgba(55,63,222,1) 0%, rgba(252,0,215,1) 77%);
    margin-bottom: 0.50rem;
}

.features-container_feature-text {
    flex: 2;
    max-width: 390px;
    display: flex;
}

.features-container_feature-text p {
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: rgb(0, 0, 0);
}

@media screen and (max-width:1218pc) {
    .features-container__feature {
        cursor: default;
    }
}

@media screen and (max-width: 550px) {
    .features-container__feature-title h1 {
        font-size: 14px;
        line-height: 22px;
    }

    .features-container_feature-text p {
        font-size: 12px;
        line-height: 20px;
    }

    .features-container__feature {
        margin: 1rem 0;
    }
}