body {
  font-family: 'Inter', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.portfolio-page {
  text-align: center;
  background-color: #ffffff;
  padding: 2rem 0;
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem 1rem;
}

.header {
  margin-bottom: 2rem;
}

.gradient-text {
  font-size: 5.5rem;
  background: linear-gradient(90deg, #c75fff, #aa36df);
  -webkit-background-clip: text;
  color: transparent;
  margin-bottom: 0.5rem;
}

.subtitle2 {
  font-family: var(--font-family);
  font-size: 1.1rem;
  color: #555;
  margin-top: 2rem;
  margin-bottom: 2rem;
  max-width: 900px;
  line-height: 2rem;
  margin-left: auto;
  margin-right: auto;
}

.project-showcase {
  padding: 0 2rem;
  max-width: 100%;
  margin: 0 auto;
}

.projects {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

.project {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.05);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.project:hover {
  transform: translateY(-5px);
}

.project img {
  width: 100%;
  height: auto;
  border-bottom: 1px solid #eee;
}

.project-info {
  padding: 1.5rem;
}

.project-info h3 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.project-info p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 1.5rem;
}

.project-button {
  background-color: #ba5fff;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.project-button:hover {
  background-color: #ad7bfe;
}
.project-buttons {
  display: flex;
  gap: 1rem;
  justify-content: center;
}

.case-study-button {
  background-color: #6c757d;
  color: #fff;
  padding: 0.75rem 1.5rem;
  border-radius: 30px;
  text-decoration: none;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.case-study-button:hover {
  background-color: #5a636d;
}

.disclaimer {
  color: #595959;
  font-size: 0.875rem;
  margin-top: 3rem;
  padding: 0 2rem;
  max-width: 100%;
  margin: 2rem auto;
}

@media (max-width: 768px) {
  .gradient-text {
    font-size: 4rem;
  }

  .subtitle2 {
    font-size: 1rem;
    margin-top: 1.5rem;
    max-width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .project-info h3 {
    font-size: 1.25rem;
  }



  .portfolio-page,
  .project-showcase,
  .disclaimer {
    padding: 2rem 1rem;
  }
}