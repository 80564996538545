/* ContactForm.css */

/* Adjust the height of the iframe */

.CareerForm-form-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow-y: auto; /* Allow overlay to scroll if content exceeds viewport */
  }
  
  .CareerForm-form {
    background: #ffffff;
    padding: 30px;
    border-radius: 10px;
    width: 90%; /* Responsive width */
    max-width: 600px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    position: relative;
    overflow-y: auto; /* Allow form to scroll if content exceeds its height */
  }
  
  .career-close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .career-close-btn:hover {
    color: rgb(144, 0, 255);
  }
  
  /* Adjust the iframe width and height */
  .CareerForm-form iframe {
    width: 100%; /* Fill the container width */
    height: 100%; /* Fill the container height */
  }
  