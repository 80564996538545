@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Questrial&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');

.cursive{
  font-family: "Pacifico", serif;
  font-weight: 400;
  font-style: normal;
  letter-spacing: 0.1em;
}

.logo-div{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  width: 80%;
  height: auto;
  margin: 0 auto;
  margin-top: 5rem;
}
.logo-div img{
  width: 50px;
  height: 50px;
  margin: 10px;
opacity: 20%;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding: 2rem;
  margin: 0 auto;
  max-width: 100%;
  overflow: hidden;
  position: relative; /* Ensure positioning context for shadow effect */
  background-color: #7300ff;
  border-bottom-left-radius: 65px;
  border-bottom-right-radius: 65px;
  cursor: none;
}

.header-content {
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  margin-left: 60px; /* Adjust this value as needed */
  margin-bottom: 2rem;
  opacity: 0;
  animation: fadeIn 1s ease forwards;
  position: relative; /* Ensure content is above shadow effect */
}

#rocket {
  position: relative;
  display: inline-block;
  transition: transform 1s ease-in-out;
  margin-left: 0.5rem;
}

.header-content b {
  color: rgb(104, 33, 179);
}

.header-content h1 {
  font-weight: 800;
  font-size: 130px;
  line-height: 120px;
  letter-spacing: -0.04em;
  line-height: 1.1em;
  color: #fff;
  margin-bottom: 1rem;
}

.header-content .h3-tag {
  font-family: var(--font-family);
  font-weight: 400;
  font-style: normal;
  font-size: 24px;
  letter-spacing: 1px;
  color: #fff;
}

.header-content p {
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 25px;
  line-height: 35px;
  color: rgb(255, 255, 255);
  margin-top: 3rem;
}

/* New Animation Styles */
.loader {
  color: rgb(124, 124, 124);
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 35px;
  height: 40px;
  display: flex;
  border-radius: 8px;
  justify-content: left;
  overflow: hidden;
}

.words {
  overflow: hidden;
  position: relative;
}

.words::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: 20;
}

/* Spinning text animation */
.word {
  display: block;
  height: 100%;
  padding-left: 6px;
  color: #ffffff;
  animation: spin_4991 6s infinite;
}

@keyframes spin_4991 {
  0%, 25% {
    transform: translateY(0);
  }

  33%, 58% {
    transform: translateY(-100%);
  }

  66%, 91% {
    transform: translateY(-200%);
  }

  100% {
    transform: translateY(-300%);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@700&family=Quicksand:wght@700&family=Barlow:wght@600&display=swap');

.word:nth-child(1) {
  font-family: 'Rubik', sans-serif; /* Modern and geometric sans-serif */
}

.word:nth-child(2) {
  font-family: 'Rubik', sans-serif; /* Rounded, playful sans-serif */
}

.word:nth-child(3) {
  font-family: 'Rubik', sans-serif; /* Clean, slightly condensed sans-serif */
}
/* Mouse-following shadow effect */
.mouse-shadow {
  position: fixed;
  width: 40px; /* Adjust based on your PNG size */
  height: 40px; /* Adjust based on your PNG size */
  background-image: url('../../assets/precision.png'); /* Add the path to your PNG file */
  background-size: cover; /* Make sure the PNG fits within the div */
  pointer-events: none;
  transform: translate(-50%, -50%);
  transition: transform 0.1s ease;
}

/* Button Styles */
.header-content__input {
  width: 18%;
  display: flex;
  justify-content: left;
  gap: 1rem; /* Add space between buttons */
  opacity: 0;
  animation: slideInButtons 1s ease forwards;
}

@keyframes slideInButtons {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.header-content__input button {
  flex: 1;
  min-width: 120px; /* Ensures minimum width for the buttons */
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  font-weight: bold;
  background: #fff; /* White background */
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-radius: 25px; /* Rounded borders */
  padding: 0 1rem;
  color: rgb(77, 0, 252); /* Gradient color for text */
  cursor: none;
  outline: none;
  margin-top: 2.5rem;
  -webkit-box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  box-shadow: 4px 8px 19px -3px rgba(0,0,0,0.27);
  transition: all 250ms ease-in-out; /* Smooth transition */
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.header-content__input button::before {
  content: '';
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, rgba(77, 0, 252, 0.1), rgba(77, 0, 252, 0.3));
  transition: all 250ms ease-in-out;
  z-index: -1;
}

.header-content__input button:hover {
  color: #fff;
  background: rgb(77, 0, 252); /* Purple background on hover */
  transform: scale(1.05); /* Slightly enlarge button */
  box-shadow: 6px 10px 25px -4px rgba(77, 0, 252, 0.5);
  transition: all 250ms ease-in-out;
}

.header-content__input button:hover::before {
  left: 0; /* Gradient slide effect */
}

/* Responsive Styles */
@media screen and (max-width: 1218px) {
  .header-content__input{
    width: 30%;
  }
  .header{
    cursor: default;
}

.header-content__input button{
  cursor: default;
}
}

@media screen and (max-width: 1050px) {
  .header {
    padding: 1rem;
  }
.loader{
  justify-content: center;
  font-size: 25px;
}
  .header-content {
    margin: 0 0 3rem;
    align-items: center;
  text-align: center;
  }

  .mouse-shadow{
    display: none !important;
  }
  .header-content__input{
    width: 50%;
  }
}

@media screen and (max-width: 650px) {
  .header-content h1 {
    font-size: 58px;
    line-height: 65px;
    max-width: 60%;
  }
  .loader{
    justify-content: center;
  }
.header-content{
  align-items: center;
  text-align: center;
}

  .header-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .header-content__input {
    flex-direction: row;
    flex-wrap: wrap; /* Allows wrapping if there's not enough space */
    justify-content: center;
    width: 50%;
  }

  .header-content__input button {
    font-size: 16px;
    line-height: 24px;
    margin-top: 3rem;
  }

  .mouse-shadow{
    display: none !important;
  }
}

@media screen and (max-width: 490px) {
  .header-content h1 {
    font-size: 56px;
    line-height: 68px;
  }
  .loader{
    justify-content: center;
  }
  .header-content{
    align-items: center;
  text-align: center;
  }

  .header-content p {
    font-size: 16px;
    line-height: 26px;
  }
  .header-content__input{
    width: 50%;
  }

  .header-content__input button {
    font-size: 12px;
    line-height: 16px;
    margin-top: 1rem;
  }

  .loader{
    font-size: 18px;
  }

  .mouse-shadow{
    display: none !important;
  }
}