.blog-container_article {
    width: 100%;
    height: 100%;
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    background: #ffffff;
    box-shadow: 0px 0px 40px rgba(0,0, 0,0.2);
    cursor: none;
}

.blog-container_article-image {
    width: 100%;
    height: 100%;
    background: transparent;
    border-top-left-radius: 60px;
    border-top-right-radius: 60px;
}

.blog-container_article-image img {
    width: 100%;
    height: 100%;
    background: transparent;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.blog-container_article-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem 1.5rem;
    height: 100%;
}

.blog-container_article-content p {
    font-family: var(--font-family);
    font-size: 11.65px;
    font-weight: 700;
    line-height: 35px;
    color: #000000;
}

.blog-container_article-content h3 {
    font-family: var(--font-family);
    font-size: 25.11px;
    font-weight: 800;
    line-height: 30.30px;
    
    color: #000000;
    margin-bottom: 5rem;
    cursor: pointer;
}

.blog-container_article-content p:last-child {
    cursor: pointer;
}
@media screen and (max-width: 1218px) {
    
    .blog-container_article{
      cursor: default;
  }
  }

@media screen and (max-width: 550px) {
    .blog-container_article-content h3 {
        font-size: 18px;
        line-height: 25p;
    }
}