/* App.css */
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}



body {
  background: #ffffff;
}

a {
  text-decoration: none;
  color: unset;
}

.gradient__bg {
  background-color: #fff;
}

.gradient__text {
  background: rgb(207, 107, 225);
  background: linear-gradient(90deg, rgba(207, 107, 225, 1) 0%, rgba(174, 0, 252, 1) 77%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section__padding {
  padding: 4rem 6rem;
}

.section__margin {
  margin: 4rem 6rem;
}

@media screen and (max-width: 700px) {
  .section__padding {
    padding: 4rem;
  }

  .section__margin {
    margin: 4rem;
  }
}

@media screen and (max-width: 550px) {
  .section__padding {
    padding: 4rem 2rem;
  }

  .section__margin {
    margin: 4rem 2rem;
}

}
