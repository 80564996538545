/* General styles for the mission section */
.mission_section__margin {
  display: flex;
  flex-direction: column;
  padding: 1.5rem;
  overflow: hidden;
  cursor: none;
}

.click-here {
  padding: 0.5rem 1rem;
  color: #fff;
  background-color:#7a6cfe;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 14px;
  line-height: 25px;
  border: none;
  outline: none;
  cursor: none;
  border-radius: 25px;
  position: absolute;
  bottom: 15px; /* Adjust as needed */
  left: 15px; /* Adjust as needed */
  box-shadow: 0 4px 25px rgba(158, 157, 157, 0.272);;
}


.click-here:hover{
  background-color: #2289ff;
}

#descriptions-heading{
  line-height: 60px;
}

/* Styles for the package headers */
.pack-headers {
  line-height: 40px;
  font-family: var(--font-family);
  font-size: 35px;
  margin-bottom: 20px; /* Increased margin for spacing */
}

.pack-headers_2 {
  line-height: 40px;
  font-family: var(--font-family);
  font-size: 40px;
  margin-bottom: 20px; /* Increased margin for spacing */
}

/* Custom list styles to remove default bullets and add custom bullets */
.custom-list {
  list-style-type: none; /* Remove default bullets */
  padding-left: 0; /* Remove default padding */
}

.custom-list li {
  position: relative; /* Position relative for the custom bullet */
  padding-left: 2px; /* Padding to make space for the custom bullet */
  margin-bottom: 10px; /* Margin for spacing between items */
  font-size: 16px; /* Font size for list items */
  line-height: 1.5; /* Line height for readability */
  margin-top: 10px; /* Added margin for spacing from the title */
}

.custom-list li::before {
  /* Custom bullet point */
  color: rgb(104, 33, 179); /* Customize bullet color */
  position: absolute; /* Position the bullet absolutely */
  left: 0; /* Align bullet to the left */
  top: 50%; /* Center the bullet vertically */
  transform: translateY(-50%); /* Adjust bullet alignment */
  font-size: 18px; /* Customize bullet size */
  line-height: 1.5; /* Match line height */
}

/* Styles for the mission heading */
.mission-heading {
  display: flex;
  flex-direction: column; /* Changed to column for vertical alignment */
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally */
  text-align: center; /* Center text */
  margin: 6rem 0 2rem;
}

.mission-heading h1 {
  font-size: 65px;
  line-height: 85px;
  font-weight: 800;
  font-family: var(--font-family);
}

.mission-heading__subtext p {
  font-family: var(--font-family);
  font-weight: 600;
  font-size: 21px;
  line-height: 30px;
  color: #000000;
  justify-content: center; /* Center items vertically */
  align-items: center; /* Center items horizontally **/
  margin-top: 3rem;
}

/* Styles for the blog container */
.blog-container_groupC {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 2rem;
  margin: 2rem;
}

/* Styles for the package container */
.pack-container {
  display: flex;
  flex-direction: row;
  position: relative; /* Needed for absolute positioning */
}

/* Container for the package article */
.package-article {
  transition: transform 0.3s ease-in-out; /* Smooth transition for the scale effect */
  position: relative; /* Needed for absolute positioning */
}

/* Hover effect for the package article */
.package-article:hover {
  transform: scale(1.01); /* Scale up the article slightly */
}

/* Responsive design adjustments */
@media screen and (max-width: 1214px) {
  .mission-heading{
    cursor: default;
  }
}

@media screen and (max-width: 1024px) {
  .click-here {
    left: 50%;
    transform: translateX(-50%);
    cursor: default;
  }
}

@media screen and (min-width: 1200px) {
  .blog-container_groupC {
    grid-template-columns: repeat(3, 1fr); /* Adjust the number of columns for larger screens */
  }
  .mission_section__margin{
    cursor: default;
}
.click-here {
  cursor: default;
}
}

@media screen and (max-width: 850px) {
  .mission-heading {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 0rem;
  }

  .mission-heading__subtext p {
    margin-top: 1rem;
  }

  .pack-container {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 650px) {
  .blog-container_groupC {
    grid-template-columns: repeat(1, 1fr);
  }

  .mission-heading h1 {
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .mission-heading__subtext {
    font-size: 28px;
    line-height: 40px;
    text-align: center;
  }

  .custom-list li {
    padding-left: 1px; /* Adjust padding for smaller screens */
    font-size: 16px; /* Adjust font size for smaller screens */
    margin-top: 5px; /* Adjust top margin for smaller screens */
  }

  .custom-list li::before {
    font-size: 16px; /* Adjust bullet size for smaller screens */
  }

  /* Adjusting font size for mission heading subtext */
 /* Adjusting font size for mission heading subtext */
.mission-heading__subtext p {
  font-size: 18px; /* Adjust font size for smaller screens */
  line-height: 24px; /* Adjust line height for smaller screens */
}

@media screen and (max-width: 350px) {
  .mission-container .features-container__feature {
    margin: 1rem 0;
    min-width: 100%;
  }
}



}