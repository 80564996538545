.career-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  font-family: Arial, sans-serif;
}

.header {
  text-align: center;
  margin-bottom: 40px;
  justify-content: center
}

.gradient__text {
  font-size: 5.5em;
  margin-top: 3rem;
  color: #a042da;
}

.purple__dis{
  color: #a042da;
}

.subtitle {
  font-size: 1.2em;
  margin-top: 3rem;
  color: #000000;
}

.joinUs {
  overflow: hidden;
  height: auto;
  width: 85%;
  margin: 3rem auto;
}

.growing {
  overflow: hidden;
  height: auto;
  width: 65%;
  margin-top: 7rem;
}

.sitting{
  overflow: hidden;
  height: auto;
  width: 65%;
  margin-top: 7rem;
  margin-bottom: 10rem;
}

.section-title {
  font-size: 2em;
  color: #6a1b9a;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 5rem;
  margin-bottom: 3rem;
}

.section-title svg {
  margin-right: 10px;
}

.opportunity {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  margin: 20px 0;
  box-shadow: 0 0px 25px rgba(0, 0, 0, 0.202);
  text-align: center;
  border-radius: 35px;
}

.opportunity-title {
  font-size: 1.5em;
  margin: 0 0 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333;
}

.opportunity-title svg {
  margin-right: 10px;
}

.opportunity-description {
  font-size: 1em;
  margin: 0 0 20px;
  color: #555;
}

.submit-button {
  background-color: #ae2bff;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 1em;
}

.submit-button:hover {
  background-color: #b200d2;
}

.coming-soon {
  padding: 20px;
  margin: 20px 0;
  text-align: center;
}

.coming-soon-description {
  font-size: 1em;
  color: #555;
}

.jotform-embed {
  margin-top: 40px;
}

.careePositions h3{
  margin-top: 2rem;
margin-bottom: 1rem;
}
.careePositions li{
  text-decoration: none;
  list-style-type: none;
  margin-bottom: 0.8rem;
}

@media (max-width: 768px) {
  .career-container {
    padding: 10px;
  }

  .title {
    font-size: 2em;
  }

  .subtitle {
    font-size: 1em;
  }

  .section-title {
    font-size: 1.5em;
  }

  .opportunity-title {
    font-size: 1.2em;
  }

  .opportunity-description {
    font-size: 0.9em;
  }

  .submit-button {
    font-size: 0.9em;
    padding: 8px 16px;
  }

  .coming-soon {
    font-size: 0.9em;
  }

  .sitting{
    overflow: hidden;
    height: auto;
    width: 65%;
    margin-top: 7rem;
    margin-bottom: 3rem;
  }

  .current-opportunities{
    margin-top: 7rem;
  }

  .opportunity {
    background: #ffffff;
    padding: 40px;
    border-radius: 28px;
    box-shadow: 0 0px 25px rgba(0, 0, 0, 0.202);
    text-align: center;
    border-radius: 35px;
    margin-top: 3rem;
  }
  
  .growing {
    margin-top: 5rem;
    margin-bottom: 3rem;
  }
  
  .gradient__text {
    font-size: 4.5em;
    margin-top: 1.5rem;
    color: #a042da;
  }
  
  .subtitle {
    font-size: 1.1em;
    margin-top: 1rem;
    color: #000000;
  }

}
