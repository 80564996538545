/* General Styling */
.about-page {
  max-width: 100%;
  margin: 0;
  padding: 40px 0;
  overflow: hidden;
  position: relative;
}

/* Header Section */
.header-section {
  position: relative;
  height: 70vh;
  background: linear-gradient(to bottom right, #ffffff, #ffffff);
  color: #000000;
  text-align: center;
  overflow: hidden;
}

.content_read_btn button {
  flex: 1;
  min-width: 120px; /* Ensures minimum width for the buttons */
  min-height: 50px;
  font-family: var(--font-family);
  font-weight: 400;
  font-size: 18px;
  line-height: 28px;
  font-weight: bold;
  background: #fff; /* White background */
  border: 2px solid;
  border-image-slice: 1;
  border-width: 2px;
  border-radius: 12px; /* Rounded borders */
  padding: 0 1rem;
  color: rgb(77, 0, 252); /* Gradient color for text */
  cursor: pointer;
  outline: none;
  margin-top: 2.5rem;
  animation: fadeInUp 1.5s;
  transition: background 0.3s ease, color 0.3s ease; /* Smooth transition */
}

/*.content_prices_btn button:hover {
  background: linear-gradient(90deg, rgba(77, 0, 252, 1) 21%, rgba(207, 107, 225, 1) 100%);
  color: #fff; /* White text on hover 
}*/

.header-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.4;
}

.header-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 80%;
  text-align: center;
}

.header-text h1 {
  font-size: 5em;
  margin: 0;
  animation: fadeInDown 1.5s;
}

.header-text p {
  font-size: 1.2em;
  margin-top: 20px;
  animation: fadeInUp 1.5s;
  display: inline-block;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Intro Section */
.intro-section {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  padding: 80px 20px;
  text-align: center;
  position: relative;
  margin-top: 5rem;
}

.intro-section .intro-content {
  max-width: 1200px;
  margin: 0 auto;
}

.intro-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  color: #333333;
}

.intro-section h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background: #8e24aa;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  border-radius:5px;
}

.intro-section p {
  font-size: 1.2em;
  line-height: 1.6;
  margin-bottom: 20px;
  color: #666666;
  animation: fadeIn 1s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Services Section */
.services-section {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  padding: 80px 20px;
  text-align: center;
  position: relative;
}

.services-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  color: #333333;
}

.services-section h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background: #8e24aa;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  border-radius:5px;
}

.services-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.service-card {
  background: #f9f9f9;
  padding: 30px;
  border-radius: 25px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.163);
  text-align: left;
  animation: slideUp 1s;
  transition: transform 0.3s ease-in-out; /* Added transition for smooth effect */
}

.service-card:hover {
  transform: translateY(-5px); /* Lift up effect on hover */
}

.service-card h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333333;
}

.service-card ul {
  list-style: none;
  padding: 0;
}

.service-card ul li {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666666;
  margin-bottom: 10px;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Philosophy Section */
.philosophy-section {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  padding: 80px 20px;
  text-align: center;
  position: relative;
}

.philosophy-section .philosophy-content {
  max-width: 800px;
  margin: 0 auto;
}

.philosophy-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  color: #333333;
}

.philosophy-section h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background: #8e24aa;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  border-radius:5px;
}

.philosophy-section p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666666;
  animation: fadeIn 1s;
}

/* Process Section */
.process-section {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  padding: 80px 20px;
  text-align: center;
  position: relative;
}

.process-section .process-content {
  max-width: 800px;
  margin: 0 auto;
}

.process-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  color: #333333;
}

.process-section h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background: #8e24aa;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  border-radius:5px;
}

.process-section p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666666;
  animation: fadeIn 1s;
  margin-bottom: 2rem;
}

.process-section ul {
  list-style: none;
  padding: 0;
}

.process-section ul li {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666666;
  margin-bottom: 10px;
}

/* Values Section */
.values-section {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  padding: 80px 20px;
  text-align: center;
  position: relative;
}

.values-section h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  position: relative;
  display: inline-block;
  color: #333333;
}

.values-section h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background: #8e24aa;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%);
  border-radius:5px;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 2rem;
}

.value-card {
  background: #ffffff;
  padding: 20px;
  border-radius: 25px;
  box-shadow: 0 0px 30px rgba(0, 0, 0, 0.163);
  text-align: left;
  animation: slideUp 1s;
  transition: transform 0.3s ease-in-out; /* Added transition for smooth effect */
}

.value-card:hover {
  transform: translateY(-5px); /* Lift up effect on hover */
}

.value-card .icon {
  font-size: 2em;
  color: #8e24aa;
  margin-bottom: 20px;
}

.value-card h3 {
  font-size: 1.8em;
  margin-bottom: 20px;
  color: #333333;
}

.value-card p {
  font-size: 1.1em;
  line-height: 1.6;
  color: #666666;
}

/* CTA Section */
.cta-section2 {
  background: linear-gradient(to bottom, #ffffff, #ffffff);
  padding: 80px 20px;
  text-align: center;
  position: relative;
}

.cta-section2 h2 {
  font-size: 2.5em;
  margin-bottom: 40px;
  position: relative;
  display:inline-block;
  color: #333333;
}

.cta-section2 h2:before {
  content: "";
  position: absolute;
  width: 50px;
  height: 5px;
  background: #8e24aa;
  left: 50%;
  bottom: -15px;
  transform: translateX(-50%);
  border-radius:5px;
}

.cta-section2 p {
  font-size: 1.2em;
  line-height: 1.6;
  color: #666666;
  animation: fadeIn 1s;
}

.cta-button2 {
  background: #8e24aa;
  color: #ffffff;
  padding: 15px 30px;
  border: none;
  border-radius: 10px;
  font-size: 1.2em;
  cursor: pointer;
  transition: background 0.3s;
  margin-top: 3rem;
}

.cta-button2:hover {
  background: #6e1b85;
}

@media (max-width: 768px) {
  .services-grid,
  .values-grid {
    grid-template-columns: 1fr;
  }
  .header-text h1 {
    font-size: 4em;
    margin: 0;
    animation: fadeInDown 1.5s;
  }
  .header-text p {
    line-height: 25px;
  }
}

