@import url('https://fonts.googleapis.com/css2?family=Ubuntu&display=swap');

:root {
  font-size: 16px;
  font-family: "Ubuntu", sans-serif;
}

.cta {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.2);
  padding: 2rem;
  margin: 4rem;
  border-radius: 3.2rem;
  background: rgb(77, 0, 252);
  background: linear-gradient(90deg, rgba(77, 0, 252, 1) 21%, rgba(207, 107, 225, 1) 100%);
  opacity: 0;
  animation: fadeIn 1.5s ease forwards;
  cursor: none;
}

.cta-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #fff;
}

.cta-content p {
  font-family: var(--font-family);
  font-size: 12px;
  line-height: 30px;
  font-weight: 500;
}

.cta-content h3 {
  font-family: var(--font-family);
  font-size: 24px;
  line-height: 45px;
  font-weight: 800;
}

.cta-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 2rem;
}

/* Gooey Animation Styles */
.cta-btn button {
  background: #000000;
  color: #ffffff;
  font-family: var(--font-family);
  font-weight: 700;
  font-size: 18px;
  line-height: 30px;
  padding: 0.5rem 1rem;
  border-radius: 2rem;
  border: none;
  outline: none;
  cursor: pointer;
  min-width: 150px;
  position: relative;
  overflow: hidden;
  z-index: 1; /* Ensure text is above the animation */
  transition: transform 0.3s ease-out;
}

.cta-btn button::before,
.cta-btn button::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background-color: rgba(106, 0, 255, 0.208);
  border-radius: 50%;
  transform: scale(1.4) translate(-50%, -50%) translateY(125%);
  transition: transform 400ms ease;
  z-index: -2; /* Place the animation behind the button text */
}

.cta-btn button::before {
  transition-delay: 60ms;
}

.cta-btn button::after {
  transition-delay: 25ms;
}

.cta-btn button:hover::before,
.cta-btn button:hover::after {
  transform: scale(1.4) translate(-50%, -5%) translateY(0);
}

@media screen and (max-width:1218px) {
  .cta{
    cursor: default;
  }
}

@media screen and (max-width: 650px) {
  .cta {
    flex-direction: column;
  }

  .cta-btn {
    margin: 2rem 0 0;
  }
}

@media screen and (max-width: 550px) {
  .cta {
    flex-direction: column;
    margin: 4rem 2rem;
  }

  .cta-content h3 {
    font-size: 18px;
    line-height: 32px;
  }

  .cta-btn button {
    font-size: 14px;
    line-height: 28px;
  }
}