/* General styles for the mission section */
#ux_section__margin {
    display: flex;
    flex-direction: column;
    padding: 2rem; /* Reduced padding */
    overflow: hidden;
  }
  
  .devs-img {
    overflow: hidden;
    height: auto;
    width: 70%;
    margin-top: 3rem;
  }
  
  .disclaimer {
    opacity: 0.5;
    font-size: 12px;
  }
  
  #description-heading{
    line-height: 64px;
}
  /* Styles for the mission heading */
  .ux-heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  /* Remove default bullet points */
  .check-ul {
    list-style-type: none;
  }
  
  .ux-heading h1 {
    font-size: 62px; /* Reduced font size */
    line-height: 85px; /* Adjusted line height */
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 1200px;
  }
  
  .ux-heading__subtext p {
    font-family: var(--font-family);
    font-weight: 400;
    font-size: 22px; /* Reduced font size */
    line-height: 28px; /* Adjusted line height */
    color: #000000;
    margin-top: 2rem; /* Reduced margin */
    text-align: center;
    margin-bottom: 1rem; /* Reduced margin */
  }
  
  .strt-from {
    opacity: 0.5;
  }
  
  .wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px; /* Reduced grid gap */
    margin-top: 0.5rem;
    padding: 3.5rem;
  }
  
  .pricing-table {
    box-shadow: 0px 0px 20px #c5c5c5; /* Reduced box shadow */
    text-align: center;
    padding: 25px 0px; /* Reduced padding */
    border-radius: 25px; /* Reduced border radius */
    position: relative;
  }
  
  .pricing-table .head {
    border-bottom: 1px solid #eee;
    padding-bottom: 40px; /* Reduced padding */
    transition: all 0.5s ease;
  }
  
  .pricing-table:hover .head {
    border-bottom: 1px solid #8E2DE2;
  }
  
  .pricing-table .head .title {
    margin-bottom: 20px;
    font-size: 18px; /* Reduced font size */
    font-weight: 700;
  }
  
  .pricing-table .content .price {
    background: linear-gradient(to right, #8E2DE2 0%, #4A00E0 100%);
    width: 100px; /* Reduced width */
    height: 85px; /* Reduced height */
    margin: auto;
    line-height: 80px; /* Adjusted line height */
    border-radius: 20px; /* Reduced border radius */
    border: 5px solid #fff;
    box-shadow: 0px 0px 10px #ccc;
    margin-top: -25px; /* Adjusted margin */
    transition: all 0.5s ease;
    color: #fff;
  }
  
  .pricing-table:hover .content .price {
    transform: scale(1.1);
  }
  
  .pricing-table .content .price h4 {
    color: #fff;
    font-size: 28px; /* Reduced font size */
    font-weight: 700;
  }
  /* Keyframes for the arrow animation */
  @keyframes moveUpDown {
    0%, 100% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-2px);
    }
  }
  
  /* Apply the animation to the arrow-icon */
  .arrow-icon{
    animation: moveUpDown 1.3s infinite;
    margin-left: 5px; /* Adjust the amount to move the arrow to the right */
    margin-top: 3px; /* Adjust the amount to move the arrow down */
    color: #8E2DE2;
  
  }
  
  /* Apply the animation to the arrow-link */
  .arrow-link {
    display: inline-block;
    position: relative;
    animation: moveUpDown 1s infinite;
  }
  
  
  .pricing-table .content ul {
    list-style-type: none;
    margin-bottom: 20px;
    padding-top: 10px;
  }
  
  .pricing-table .content ul li {
    margin: 1rem; /* Reduced margin */
    font-size: 14px; /* Adjusted font size */
    color: #000000;
  }
  
  .pricing-table .content .sign-up {
    background: linear-gradient(to right, #8E2DE2 0%, #4A00E0 100%);
    border-radius: 30px; /* Reduced border radius */
    font-weight: 500;
    position: relative;
    display: inline-block;
  }
  
  .pricing-table .btn {
    color: #fff;
    padding: 10px 30px; /* Reduced padding */
    display: inline-block;
    text-align: center;
    font-weight: 600;
    transition: all 0.3s linear;
    border: none;
    font-size: 14px;
    text-transform: capitalize;
    position: relative;
    margin: 2px;
    z-index: 9999;
    border-radius: 40px; /* Reduced border radius */
    text-decoration: none;
  }
  
  .pricing-table .btn:hover {
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3); /* Reduced box shadow */
  }
  
  .pricing-table .btn.bordered {
    z-index: 50;
    color: #333;
  }
  
  .pricing-table:hover .btn.bordered {
    color: #fff !important;
  }
  
  .pricing-table .btn.bordered:after {
    background: #fff none repeat scroll 0 0;
    border-radius: 40px;
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    transition: all 0.3s linear;
    width: 100%;
    z-index: -1;
    transform: scale(1);
  }
  
  .pricing-table:hover .btn.bordered:after {
    opacity: 0;
    transform: scale(0);
  }
  
  
  /* Responsive adjustments */
  @media screen and (max-width: 850px) {
    .ux-heading {
      align-items: center;
      margin-bottom: 0rem;
    }
  
    .ux-heading__subtext p {
      margin-top: 1.5rem; /* Adjusted margin */
    }
      
  #description-heading{
    line-height: 65px;
}
  }
  
  @media screen and (max-width: 768px) {
    .wrapper {
      grid-template-columns: repeat(2, 1fr);
    }
      
  #description-heading{
    line-height: 64px;
}
  }
  
  @media screen and (max-width: 650px) {
    .ux-heading h1,
    .ux-heading__subtext p {
      font-size: 42px; /* Adjusted font size */
      line-height: 38px; /* Adjusted line height */
    }
  
    .ux-heading__subtext p {
      font-size: 16px;
      line-height: 24px;
    }
      
  #description-heading{
    line-height: 64px;
}
  }
  
  @media screen and (max-width: 550px) {
    #service_section__margin {
      padding: 1rem;
    }
  
    .wrapper {
      grid-template-columns: 1fr;
    }
  
    .pricing-table {
      padding: 15px 0px;
      box-shadow: 0px 0px 10px #c5c5c5; /* Reduced box shadow */
      border-radius: 15px; /* Reduced border radius */
    }
  
    .ux-heading h1 {
      font-size: 35px; /* Adjusted font size */
      line-height: 45px; /* Adjusted line height */
    }
  
    .ux-heading__subtext p {
      font-size: 14px;
      line-height: 20px;
    }
      
  #description-heading{
    line-height: 45px;
}
  }

  @media screen and (max-width: 430px) {
  #description-heading{
    line-height: 40px;
}
  }


  