.features {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    overflow: hidden;
    cursor: none;
}

.features-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    text-align: left;
    margin-right: 5rem;
}

.features-heading h1 {
    font-size: 50px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.features-heading p {
    color: #000000;
    font-size:18px;
    line-height: 30px;
    font-weight: 800;
    font-family: var(--font-family);
    margin-top: 2rem;
}


.features-heading b {
    color: rgb(104, 33, 179);
}

.features-container {
    flex: 1.5;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
}



/*----------- MEDIA QUERIES ------------*/
@media screen and  (max-width: 1218px){
    .features{
        cursor: default;
    }
}


@media screen and (max-width: 990px) {
    .features {
        flex-direction: column;
    }

    .features-heading {
        margin: 0 0 2rem 0;
    }
}


@media screen and (max-width: 550px) {
    .features-heading h1 {
        font-size: 28px;
        line-height: 38px;
    }

    .features-heading p {
        text-align: left;
        font-size: 15px;
    }
}