.card-div{
padding: 1rem;
border-radius: 25px;
border-color: rgba(180, 33, 203, 0.247);
border-width: 2px;
border-style:groove;
align-content: center;
align-items: center;
justify-content: center;
margin-left: 1.4rem;
}