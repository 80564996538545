/* Requirements.css */
.requirements-container {
    padding: 20px;
    background-color: #ffffff; /* Set background to white */
  }
  
  .requirements-section {
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  .requirements-section h1 {
    font-size: 48px;
    color: #9636da; /* Indigo color for heading */
    margin-bottom: 20px;
  }
  
  .requirements-section_subtext {
    margin-bottom: 40px;
    line-height: 1.8;
    font-size: 18px;
    color: #333333; /* Dark text color for readability */
  }
  
  .text__h1{
    font-size: 28px;
    font-weight: bold;
    line-height: 1.2;
    margin-bottom: 20px;
    color: #9636da; 
  }
  
  .white-text {
    color: #ffffff;
  }
  
  .requirements-article {
    background-color: #c10707; /* Light grey background for articles */
    padding: 30px;
    border-radius: 10px;
    margin-bottom: 30px;
    color: #333333;
    transition: transform 0.3s ease-in-out;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .requirements-article:hover {
    transform: translateY(-10px); /* Slight lift on hover */
  }
  
  .requirements-heading-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
  }
  
  .requirements-heading-container h2 {
    margin-right: 10px;
    font-size: 24px;
    color: #9636da;  /* Indigo color for subheadings */
  }
  
  .icon {
    font-size: 28px;
    color: #800080; /* Purple color for icons */
  }
  
  .feature-card {
    padding: 20px;
    background-color: #f6e4ff; /* Slightly darker grey for cards */
    border-radius: 12px;
    margin-bottom: 20px;
    transition: background-color 0.3s ease;
  }
  
  .feature-card:hover {
    background-color: #f7e7ff; /* Darker grey on hover */
  }
  
  .feature-card p {
    line-height: 1.6;
    font-size: 16px;
    color: #000000; /* Dark grey text */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 768px) {
    .text {
      font-size: 36px;
    }
  
    .requirements-article {
      padding: 20px;
    }
  
    .icon-wrapper {
      text-align: center;
      margin-bottom: 4rem;
    }
  }
  