/* src/Containers/ProjectCarousel/ProjectCarousel.css */
.section-project {
    padding: 50px 0;
    position: relative;
    overflow: hidden; /* Prevent horizontal scrolling */
  }
  
  .slider {
    height: 370px;
    margin-top: 4rem;
    overflow: hidden;
    position: relative;
    width: 100%; /* Adjusted width */
  }
  
  .slider::before,
  .slider::after {
    content: "";
    height: 100px;
    position: absolute;
    width: 200px;
    z-index: 2;
  }
  
  .slider::after {
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
  
  .slider::before {
    left: 0;
    top: 0;
  }
  
  .slide-track {
    animation: scroll 25s linear infinite;
    display: flex;
    width: auto; /* Adjusted width */
  }
  
  .slide {
    margin-right: 2rem;
    border-radius: 15px; /* Adjusted border-radius */
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(calc(-100% + 2rem)); /* Adjusted translation */
    }
  }
  
  @media (max-width: 768px) {
    .section__title {
      font-size: 1.5em;
    }
  
    .section__subtitle {
      font-size: 0.9em;
    }
  }
  
  @media (max-width: 480px) {
    .section__title {
      font-size: 1.2em;
    }
  
    .section__subtitle {
      font-size: 0.8em;
    }
  }
  