.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 6rem;
    background-color: #7300ff;
}

.navbar-links {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
}

.hover-underline-animation {
    display: inline-block;
    position: relative;
    color: #ffffff; /* Change color as needed */
    
}

.hover-underline-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    background-color: rgb(200, 147, 247); /* Change color as needed */
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover::after {
    transform: scaleX(1);
    transform-origin: bottom left;
}

.navbar-links_logo {
    margin-right: 3rem;
    margin-top: 0.5rem;
}

.navbar-links_logo img {
    width: 185.56px;
    height: 92.02px;
}

.navbar-links_container {
    display: flex;
    flex-direction: row;
}

.navbar-links_services {
    position: relative;
}
.navbar-links_services p{
    margin: 0; /* Remove top and bottom margins */
    cursor: pointer;
    display: flex;
    align-items: center; /* Align arrow icons */
}
.white-text {
    color: white !important;
  }
  
  .black-text {
    color: black !important;
  }


.dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Add shadow */
    z-index: 1;
    border-radius: 15px;
    overflow: hidden;
    padding: 25px 10px; /* Add padding for spacing */
    border: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown p {
    margin: 0;
    padding: 10px 20px;
    cursor: pointer;
}
/* Default navbar background */
.navbar-default {
    background-color: #7300ff;
  }
  
  /* Mission page navbar */
  .navbar-mission {
    background-color: #fff;
  }
  
  /* Services page navbar */
  .navbar-services {
    background-color: #fff;
  }
  

.dropdown p a {
    color: #000;
    text-decoration: none;
    border-radius: 20px;
    font-size: 17px;
}

.dropdown p:hover {
    background-color: #6f12a91f;
    border-radius: 10px;
}

.navbar-sign {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.navbar-links_container p, 
.navbar-sign p, 
.navbar-menu_container p {
    color: #000000;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    text-transform: capitalize;
    margin: 0 1rem;
    cursor: pointer;
}

.navbar-sign button,
.navbar-menu_container button {
    padding: 0.5rem 1rem;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    outline: none;
    cursor: pointer;
    border-radius: 20px;
    background: rgb(115, 45, 255);
    background: linear-gradient(177deg, rgb(115, 45, 255) 0%, rgb(115, 45, 255) 100%);
        border: 2px solid;
        color: #fff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

.navbar-menu {
    margin-left: 1rem;
    display: none;
    position: relative;
}

.navbar-menu svg {
    cursor: pointer;
    
}

.navbar-menu_container {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    text-align: end;
    background: #fff;
    padding: 2rem;
    position: absolute;
    right: 0;
    top: 40px;
    margin-top: 1rem;
    min-width: 210px;
    border-radius: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
    border-style: solid;
    border-color: #dedede;
    
}

.navbar-menu_container p {
    margin: 1rem 0;
    
}
.navbar-menu_container .services__Link {
    margin: 0rem;
    left: 1rem;
}

.navbar-menu_container-links-sign {
    display: none;
}

@media screen and (max-width: 1050px) {
    .navbar-links_container {
        display: none;
    }

    .navbar-menu {
        display: flex;
    }

    .navbar-menu {
        z-index: 999; /* Ensure the navbar appears above other content */
        transition: top 0.3s; /* Add a smooth transition effect */
    }
    .navbar-menu_dropdown p{
        font-size: 14px;
        margin: 1rem auto;
    }
    .navbar-menu_dropdown p{
        font-size: 14px;
        margin: 0rem auto;
        justify-content: left;
        text-align: left;
        padding: 10px;
    }
    .navbar-menu_dropdown p:hover{
        background-color: #6f12a91f;
        border-radius: 10px;
    }

    .navbar__dropdown__divider{
        margin-top: 1rem;
    margin-bottom: 1rem;
    }

    .navbar__dropdown__divider2{
        margin-top: 1rem;
        }
        
        .hover-underline-animation{
            color: #000;
        }

        .navbar-sign button,
.navbar-menu_container button {
    background: rgb(115, 45, 255);
    background: linear-gradient(177deg, rgb(115, 45, 255) 0%, rgb(115, 45, 255) 100%);
        border: 2px solid;
        color: #fff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}

        
}

@media screen and (max-width: 700px) {
    .navbar {
        padding: 2rem 4rem;
    }
    .navbar-menu_dropdown p{
        font-size: 14px;
        margin: 0.5rem auto;
    }
    .navbar-menu_dropdown p{
        font-size: 14px;
        margin: 0rem auto;
        justify-content: left;
        text-align: left;
        padding: 10px;
    }
    .navbar-menu_dropdown p:hover{
        background-color: #6f12a91f;
        border-radius: 10px;
    }

    .navbar__dropdown__divider{
        margin-top: 1rem;
    margin-bottom: 1rem;
    }

    .navbar__dropdown__divider2{
        margin-top: 1rem;
        }

        .navbar-sign button,
.navbar-menu_container button {
    background: rgb(115, 45, 255);
    background: linear-gradient(177deg, rgb(115, 45, 255) 0%, rgb(115, 45, 255) 100%);
        border: 2px solid;
        color: #fff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}
}

@media screen and (max-width: 550px) {
    .navbar {
        padding: 2rem;
    }

    .navbar-sign {
        display: none;
    }

    .navbar-menu_container {
        top: 20px;
    }

    .navbar-menu_container-links-sign {
        display: block;
    }
    .navbar-menu_dropdown p{
        font-size: 14px;
        margin: 0rem auto;
        justify-content: left;
        text-align: left;
        padding: 10px;
    }
    .navbar-menu_dropdown p:hover{
        background-color: #6f12a91f;
        border-radius: 10px;
    }

    .navbar__dropdown__divider{
        margin-top:1rem ;
    margin-bottom: 1rem;
    }

    .navbar__dropdown__divider2{
        margin-top: 1rem;
        }
        .navbar-sign button,
.navbar-menu_container button {
    background: rgb(115, 45, 255);
    background: linear-gradient(177deg, rgb(115, 45, 255) 0%, rgb(115, 45, 255) 100%);
        border: 2px solid;
        color: #fff;
        box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
}
}
